import axios from "axios";

const baseUrl = "https://api.4sightlabs.com/CP-Pro-API";

const getUserInfo = (token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getUserInfo?new=New`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getActiveMonitoringSessions = (entityId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getActiveMonitoringSessions?entityId=${entityId}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getActiveMonitoringSessionBySubjectId = (entityId, subjectId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getActiveMonitoringSessionBySubjectId?entityId=${entityId}&subjectId=${subjectId}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getActiveMonitoringSessionsBySubscription = (
  entityId,
  connectionType,
  token,
  entityName
) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";

  const config = {
    method: "GET",
    url: `${baseUrl}/getActiveMonitoringSessionsBySubscription?entityId=${entityId}&connectionType=${connectionType}${entityNameQueryParam}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const negotiate = (token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/negotiate`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const joinSignalRGroups = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/joinSignalRGroups`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const removeSignalRGroups = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/removeSignalRGroups`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getEntityRouters = (entityId, token, entityName) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";

  const config = {
    method: "GET",
    url: `${baseUrl}/getEntityRouters?entityId=${entityId}&web=Web${entityNameQueryParam}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getImageSasToken = (entityId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getImageSasToken?entityId=${entityId}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const stopMonitoringSession = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/stopMonitoringSession`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const resetCriticalAlert = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/resetCriticalAlert`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getArchiveData = (
  subjectId,
  startTime,
  endTime,
  token,
  defaultBiometricsThresholds
) => {
  const defaultBiometricsThresholdsQueryParam = defaultBiometricsThresholds
    ? `&defaultBiometricsThresholds=${defaultBiometricsThresholds}`
    : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getArchiveData?subjectId=${subjectId}&startTime=${startTime}&endTime=${endTime}${defaultBiometricsThresholdsQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getEntityUsers = (entityId, pageSize, pageNumber, token, entityName) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";

  const config = {
    method: "GET",
    url: `${baseUrl}/getEntityUsers?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}${entityNameQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getEntityUsersBySearch = (
  entityId,
  pageSize,
  pageNumber,
  search,
  token,
  entityName
) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";

  const config = {
    method: "GET",
    url: `${baseUrl}/getEntityUsersBySearch?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}${entityNameQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const inviteUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/inviteUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const changeUsersRole = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/changeUsersRole`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const changeHeartRateThreshold = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/changeHeartRateThreshold`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const pinSubject = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/pinSubject`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const unpinSubject = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/unpinSubject`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getAlertHistory = (
  subjectId,
  pageSize,
  pageNumber,
  transferredFrom,
  token
) => {
  const doestransferredFromExist = transferredFrom
    ? `&transferredFrom=${encodeURIComponent(JSON.stringify(transferredFrom))}`
    : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getAlertHistory?subjectId=${subjectId}&pageSize=${pageSize}&pageNumber=${pageNumber}${doestransferredFromExist}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const updateUserPreference = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateUserPreference`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getEntitySubjectsByPagination = (
  entityId,
  pageSize,
  pageNumber,
  token,
  entityName
) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getEntitySubjectsByPagination?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}${entityNameQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getEntitySubjectsBySearchPagination = (
  entityId,
  pageSize,
  pageNumber,
  search,
  token,
  entityName
) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";

  const config = {
    method: "GET",
    url: `${baseUrl}/getEntitySubjectsBySearchPagination?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}${entityNameQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getSubjectsProfile = (entityId, subjectId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getSubjectsProfile?entityId=${entityId}&subjectId=${subjectId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getPdfSasLink = (entityId, subjectId, date, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getPdfSasLink?entityId=${entityId}&subjectId=${subjectId}&date=${date}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getEntitiesByPagination = (pageNumber, pageSize, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getEntitiesByPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getEntitiesBySearchPagination = (pageNumber, pageSize, search, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getEntitiesBySearchPagination?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const checkDeviceData = (deviceId, subjectId, minutesAgo, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/checkDeviceData?deviceId=${deviceId}&subjectId=${subjectId}&minutesAgo=${minutesAgo}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const createEntity = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/createEntity`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getEntitysInformation = (entityId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getEntitysInformation?entityId=${entityId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const registerRouter = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/registerRouter`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const removeUsersNfcTools = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/removeUsersNfcTools`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const addUsersNfcTools = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/addUsersNfcTools`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getSubjectsInteractionHistoryByPagination = (
  subjectId,
  pageSize,
  pageNumber,
  token
) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getSubjectsInteractionHistoryByPagination?subjectId=${subjectId}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const logUsersAlertAction = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/logUsersAlertAction`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const publicDataProcessor = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://api.4sightlabs.com/publicDataShare/publicDataProcessor",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const score = (payload) => {
  const config = {
    method: "POST",
    url: "https://api.4sightlabs.com/score",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer hjCszBMJO1axJMxPhwNA81cQeQ06cb1b",
    },
  };
  return axios(config);
};
const chatBotSupportNotification = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/chatBotSupportNotification`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const joinSignalRGroupsV2 = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/joinSignalRGroupsV2`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const removeSignalRGroupsV2 = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/removeSignalRGroupsV2`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getDailyAnalyticsSubjectHealth = (
  entityId,
  pageSize,
  pageNumber,
  token
) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getDailyAnalyticsSubjectHealth?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getDailyAnalyticsMonitoringUsage = (entityId, entityName, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getDailyAnalyticsMonitoringUsage?entityId=${entityId}&entityName=${entityName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getDailyAnalyticsGatewayUptime = (entityId, entityName, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getDailyAnalyticsGatewayUptime?entityId=${entityId}&entityName=${entityName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getDailyAnalyticsUserRoleStats = (entityId, entityName, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getDailyAnalyticsUserRoleStats?entityId=${entityId}&entityName=${entityName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getDailyAnalyticsAlertsIssued = (entityId, entityName, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getDailyAnalyticsAlertsIssued?entityId=${entityId}&entityName=${entityName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getDailyAnalyticsUserActions = (entityId, entityName, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getDailyAnalyticsUserActions?entityId=${entityId}&entityName=${entityName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const changeUsersTitle = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/changeUsersTitle`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const changeGatewayLocation = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/changeGatewayLocation`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getCheckedOutSensors = (
  entityId,
  pageSize,
  pageNumber,
  token,
  entityName
) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";

  const config = {
    method: "GET",
    url: `${baseUrl}/getCheckedOutSensors?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}${entityNameQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getCheckedOutSensorsBySearch = (
  entityId,
  pageSize,
  pageNumber,
  search,
  token,
  entityName
) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";

  const config = {
    method: "GET",
    url: `${baseUrl}/getCheckedOutSensorsBySearch?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}${entityNameQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getUsersData = (userId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getUsersData?userId=${userId}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const updateEntitySSIDPass = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateEntitySSIDPass`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const createFakeUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/createFakeUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const updateEntityRoles = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateEntityRoles`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const updateEntityAdIntegration = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateEntityAdIntegration`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const updateEntityLogo = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateEntityLogo`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const updateEntityEmails = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateEntityEmails`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const updateEntityTitles = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateEntityTitles`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const updateEntityPersistentLogIn = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateEntityPersistentLogIn`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const deleteUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/deleteUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const updateEntityAutoDisconnect = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/updateEntityAutoDisconnect`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const logWellnessRecord = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/logWellnessRecord`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getHourlyWellnessData = (subjectId, entityId, transferredFrom, token) => {
  const doestransferredFromExist = transferredFrom
    ? `&transferredFrom=${encodeURIComponent(JSON.stringify(transferredFrom))}`
    : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getHourlyWellnessData?subjectId=${subjectId}&entityId=${entityId}${doestransferredFromExist}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getDailyWellnessData = (subjectId, entityId, transferredFrom, token) => {
  const doestransferredFromExist = transferredFrom
    ? `&transferredFrom=${encodeURIComponent(JSON.stringify(transferredFrom))}`
    : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getDailyWellnessData?subjectId=${subjectId}&entityId=${entityId}${doestransferredFromExist}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getDailyWellnessDataByPagination = (
  subjectId,
  entityId,
  pageNumber,
  pageSize,
  startTime,
  endTime,
  transferredFrom,
  token
) => {
  const doestransferredFromExist = transferredFrom
    ? `&transferredFrom=${encodeURIComponent(JSON.stringify(transferredFrom))}`
    : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getDailyWellnessDataByPagination?subjectId=${subjectId}&entityId=${entityId}&pageNumber=${pageNumber}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}${doestransferredFromExist}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getHourlyWellnessDataByPagination = (
  subjectId,
  entityId,
  pageNumber,
  pageSize,
  startTime,
  endTime,
  transferredFrom,
  token
) => {
  const doestransferredFromExist = transferredFrom
    ? `&transferredFrom=${encodeURIComponent(JSON.stringify(transferredFrom))}`
    : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getHourlyWellnessDataByPagination?subjectId=${subjectId}&entityId=${entityId}&pageNumber=${pageNumber}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}${doestransferredFromExist}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getMinutelyWellnessDataByPagination = (
  subjectId,
  entityId,
  pageNumber,
  pageSize,
  startTime,
  endTime,
  defaultBiometricsThresholds,
  transferredFrom,
  token
) => {
  const doestransferredFromExist = transferredFrom
    ? `&transferredFrom=${encodeURIComponent(JSON.stringify(transferredFrom))}`
    : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getMinutelyWellnessDataByPagination?subjectId=${subjectId}&entityId=${entityId}&pageNumber=${pageNumber}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}&defaultBiometricsThresholds=${defaultBiometricsThresholds}${doestransferredFromExist}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const createInstantCriticalAlert = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/createInstantCriticalAlert`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const reportGeneratorHTTP = (payload, token) => {
  const config = {
    method: "POST",
    url: `https://api.4sightlabs.com/publicDataShare/reportGeneratorHTTP`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getDataForDownloadPdfSasLink = (filePath, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getDataForDownloadPdfSasLink?filePath=${filePath}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getAnalyticsData = (entityId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getAnalyticsData?entityId=${entityId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const createFeedback = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/createFeedback`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getSuperAnalyticsData = (token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/getSuperAnalyticsData`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const transferSubjects = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/transferSubjects`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const createAlertGroup = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/createAlertGroup`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const deleteAlertGroup = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/deleteAlertGroup`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const addSubjectsToAlertGroup = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/addSubjectsToAlertGroup`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const removeSubjectsFromAlertGroup = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/removeSubjectsFromAlertGroup`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const createAcknowledgeDeviceNotification = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/createAcknowledgeDeviceNotification`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const createGeneralFeedback = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/createGeneralFeedback`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const createSubject = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/createSubject`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const editSubject = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/editSubject`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getEntityActiveSubjectsByPagination = (
  entityId,
  pageSize,
  pageNumber,
  token,
  entityName
) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getEntityActiveSubjectsByPagination?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}${entityNameQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getEntityActiveSubjectsBySearchPagination = (
  entityId,
  pageSize,
  pageNumber,
  search,
  token,
  entityName
) => {
  const entityNameQueryParam = entityName ? `&entityName=${entityName}` : "";
  const config = {
    method: "GET",
    url: `${baseUrl}/getEntityActiveSubjectsBySearchPagination?entityId=${entityId}&pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}${entityNameQueryParam}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const deleteSubject = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/deleteSubject`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const deleteRouter = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/deleteRouter`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const userService = {
  deleteRouter,
  deleteSubject,
  getEntityActiveSubjectsBySearchPagination,
  getEntityActiveSubjectsByPagination,
  editSubject,
  createSubject,
  createGeneralFeedback,
  createAcknowledgeDeviceNotification,
  removeSubjectsFromAlertGroup,
  addSubjectsToAlertGroup,
  deleteAlertGroup,
  createAlertGroup,
  transferSubjects,
  getSuperAnalyticsData,
  createFeedback,
  getAnalyticsData,
  getDataForDownloadPdfSasLink,
  reportGeneratorHTTP,
  createInstantCriticalAlert,
  getMinutelyWellnessDataByPagination,
  getHourlyWellnessDataByPagination,
  getDailyWellnessDataByPagination,
  getDailyWellnessData,
  getHourlyWellnessData,
  logWellnessRecord,
  updateEntityAutoDisconnect,
  deleteUser,
  updateEntityPersistentLogIn,
  updateEntityTitles,
  updateEntityEmails,
  updateEntityLogo,
  updateEntityAdIntegration,
  updateEntityRoles,
  createFakeUser,
  updateEntitySSIDPass,
  getUsersData,
  getCheckedOutSensors,
  getCheckedOutSensorsBySearch,
  changeGatewayLocation,
  changeUsersTitle,
  getDailyAnalyticsUserActions,
  getDailyAnalyticsAlertsIssued,
  getDailyAnalyticsUserRoleStats,
  getDailyAnalyticsGatewayUptime,
  getDailyAnalyticsMonitoringUsage,
  getDailyAnalyticsSubjectHealth,
  joinSignalRGroupsV2,
  removeSignalRGroupsV2,
  chatBotSupportNotification,
  score,
  publicDataProcessor,
  logUsersAlertAction,
  getSubjectsInteractionHistoryByPagination,
  addUsersNfcTools,
  removeUsersNfcTools,
  registerRouter,
  getEntitysInformation,
  createEntity,
  checkDeviceData,
  getEntitiesByPagination,
  getEntitiesBySearchPagination,
  getPdfSasLink,
  getSubjectsProfile,
  getEntitySubjectsByPagination,
  getEntitySubjectsBySearchPagination,
  getActiveMonitoringSessionsBySubscription,
  updateUserPreference,
  getAlertHistory,
  pinSubject,
  unpinSubject,
  changeHeartRateThreshold,
  changeUsersRole,
  inviteUser,
  getEntityUsersBySearch,
  getEntityUsers,
  getArchiveData,
  resetCriticalAlert,
  stopMonitoringSession,
  getImageSasToken,
  getUserInfo,
  getActiveMonitoringSessions,
  getActiveMonitoringSessionBySubjectId,
  negotiate,
  joinSignalRGroups,
  removeSignalRGroups,
  getEntityRouters,
};
export default userService;
